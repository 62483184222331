import { Checkbox } from '@/components/ui/checkbox.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from '@/components/ui/form.tsx';

interface TermsConditionsProps {
  loading: boolean;
  onSubmit: () => void;
}

const FormSchema = z.object({
  termsAccepted: z.boolean().default(false),
});

const TermsConditions = ({ loading, onSubmit }: TermsConditionsProps) => {
  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      termsAccepted: false,
    },
  });

  const isChecked = form.watch('termsAccepted').valueOf();

  return (
    <div className="mx-auto">
      <div className="flex flex-col gap-y-8 max-w-[640px]">
        <section>
          <h1 className="text-2xl font-bold">Terms and Conditions</h1>
          <p>
            Before we create your account, please take a moment to read and
            agree to Peopled’s Terms and Conditions. Your trust is important to
            us, and we want you to feel confident in how we manage your
            organization’s information. Our terms outline the ways we protect
            your data, what we do with it, and importantly, what we do not do
            with it.
          </p>
        </section>

        <section>
          <h2 className="font-bold text-xl">Peopled’s Terms & Conditions</h2>
          <p>
            By using our service, you agree to our user-friendly terms designed
            to protect both you and us. We prioritize your privacy and ensure
            your data is securely handled and used only to improve your
            experience. If you have any questions or need assistance, our
            support team is always here to help. Enjoy exploring the innovative
            features of our tool, and happy benefiting!
          </p>
        </section>

        <section>
          <h3 className="font-bold text-lg">Terms of Use</h3>
          <p>
            By using our AI-powered product, you’re agreeing to a few simple
            terms. We use your data to provide personalized recommendations and
            improve your experience. We promise to keep your information safe
            and never share it with third parties without your permission. You
            can opt out of data collection at any time, but keep in mind that
            some features may not work as well without it. If you have any
            questions or concerns, our support team is always here to help.
          </p>
        </section>

        <section>
          <h3 className="font-bold text-lg">Data Collection</h3>
          <p>
            We collect data like your usage patterns, preferences, and
            interactions to make our AI smarter and more helpful to you. This
            data helps us tailor the product to better suit your needs and offer
            more accurate suggestions. Rest assured, all collected data is
            anonymized and encrypted to ensure your privacy and security.
          </p>
        </section>

        <section>
          <Form {...form}>
            <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
              <FormField
                control={form.control}
                name="termsAccepted"
                render={({ field }) => (
                  <FormItem className="flex flex-row items-start space-x-3 space-y-0 ">
                    <FormControl>
                      <Checkbox
                        checked={field.value}
                        onCheckedChange={field.onChange}
                      />
                    </FormControl>
                    <div className="space-y-1 leading-none">
                      <FormLabel>
                        I have read and agree to these terms and conditions.
                      </FormLabel>
                    </div>
                  </FormItem>
                )}
              />
              <Button loading={loading} disabled={!isChecked} type="submit">
                Submit
              </Button>
            </form>
          </Form>
        </section>
      </div>
    </div>
  );
};

export default TermsConditions;
