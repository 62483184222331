import { BiUser } from 'react-icons/bi';
import { useAuth } from '@/providers/auth.tsx';
import AccountLayout from '@/routes/account/layout.tsx';
import { Button } from '@/components/ui/button.tsx';
import { useEffect, useState } from 'react';
import AccountPersonalDetails from '@/routes/account/personal/details.tsx';
import EditPersonalDetailsForm from '@/routes/account/personal/edit-form.tsx';
import { Card } from '@/components/ui/card.tsx';
import { sdk } from '@/api.ts';

export default function AccountPersonal() {
  const { user } = useAuth();
  const [maritalStatuses, setMaritalStatuses] = useState<
    Record<string, string>
  >({});
  const [editable, setEditable] = useState(false);

  // Initialize marital status options for dropdown
  useEffect(() => {
    sdk.getMaritalStatuses().then((data) => {
      setMaritalStatuses(data);
    });
  }, []);

  return (
    <AccountLayout>
      <Card>
        <div className="flex justify-between mb-4">
          <div className="flex items-center">
            <BiUser size={24} />
            <h3 className="text-sm font-bold ml-2">Personal Information</h3>
          </div>
          <Button
            variant={editable ? 'secondary' : 'default'}
            className="rounded-full"
            onClick={() => setEditable(!editable)}
          >
            {editable ? 'Cancel' : 'Edit'}
          </Button>
        </div>
        {!editable ? (
          <AccountPersonalDetails
            user={user}
            maritalStatuses={maritalStatuses}
          />
        ) : (
          <EditPersonalDetailsForm
            user={user}
            maritalStatuses={maritalStatuses}
          />
        )}
      </Card>
    </AccountLayout>
  );
}
