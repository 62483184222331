// ts-ignore
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select.tsx';
import { statesList } from '@/lib/utils.ts';
import { ReactNode, useEffect, useState } from 'react';
import { sdk } from '@/api.ts';
import { Button } from '@/components/ui/button.tsx';
import { IOrganization } from '@/types.ts';

const formSchema = z.object({
  name: z.string().min(2).max(50),
  business_address: z.string().min(2).max(50).optional().or(z.literal('')),
  business_address_line_two: z
    .string()
    .min(2)
    .max(50)
    .optional()
    .or(z.literal('')),
  business_address_city: z.string().min(2).max(50).optional().or(z.literal('')),
  business_address_state: z.string().optional().or(z.literal('')),
  business_address_postal_code: z
    .string()
    .regex(/^\d{5}$/)
    .optional()
    .or(z.literal('')),
  business_tax_id: z
    .string()
    .regex(/^\d{2}-\d{7}$/)
    .optional()
    .or(z.literal('')),
  primary_contact_full_name: z
    .string()
    .min(2)
    .max(50)
    .optional()
    .or(z.literal('')),
  primary_contact_email: z.string().email().optional().or(z.literal('')),
  primary_contact_phone_number: z
    .string()
    .regex(/^\d{3}-\d{3}-\d{4}$/)
    .optional()
    .or(z.literal('')),
});

const formDefaults = {
  name: '',
  business_address: '',
  business_address_line_two: '',
  business_address_city: '',
  business_address_state: '',
  business_address_postal_code: '',
  business_tax_id: '',
  primary_contact_full_name: '',
  primary_contact_email: '',
  primary_contact_phone_number: '',
};
export default function OrganizationForm({
  onSuccess,
  organization,
}: {
  organization?: IOrganization;
  onSuccess: () => void;
}) {
  const [statusMessage, setStatusMessage] = useState<ReactNode>('');
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: organization
      ? {
          ...formDefaults,
          ...Object.fromEntries(
            // replacing null values with empty strings
            Object.entries(organization).map(([key, value]) => [
              key,
              value === null ? '' : value,
            ])
          ),
        }
      : formDefaults,
  });

  // Using an effect here as a watcher to manage the button / message states
  useEffect(() => {
    if (loading) {
      setStatusMessage('');
      return;
    }
    if (success) {
      return;
    }
    if (error) {
      setStatusMessage('Error creating organization.');
      return;
    }

    setStatusMessage('');
  }, [loading, success, error]);

  const onSubmit = async (data: z.infer<typeof formSchema>) => {
    setLoading(true);
    sdk
      .updateOrganization(organization!.id, {
        ...data,
      })
      .then(() => {
        setSuccess(true);
        setStatusMessage('Organization updated successfully');
        onSuccess();
        setError(false);
        setLoading(false);
      })
      .catch(() => {
        setError(true);
        setSuccess(false);
        setLoading(false);
      });
  };
  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <h1 className="text-xl font-black">Org Information</h1>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem className="flex items-center my-2">
                <FormLabel className="flex-1">Company Name</FormLabel>
                <div className="flex flex-col">
                  <FormControl className="flex-1">
                    <Input 
                    test-id="org-name-input"
                    {...field} />
                  </FormControl>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="business_tax_id"
            render={({ field }) => (
              <FormItem className="flex items-center my-2 justify-between">
                <FormLabel className="flex-1">EIN</FormLabel>
                <div className="flex flex-col">
                  <FormControl className="flex-1">
                    <Input 
                    test-id="org-business-tax-id-input"
                    placeholder="12-3456789" {...field} />
                  </FormControl>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <h1 className="text-xl font-bold">Org Address</h1>
          <FormField
            control={form.control}
            name="business_address"
            render={({ field }) => (
              <FormItem className="flex items-center my-2 justify-between">
                <FormLabel className="flex-1">Address 1</FormLabel>
                <div className="flex flex-col">
                  <FormControl className="flex-1">
                    <Input 
                    test-id="org-business-address-input"
                    {...field} />
                  </FormControl>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="business_address_line_two"
            render={({ field }) => (
              <FormItem className="flex items-center my-2 justify-between">
                <FormLabel className="flex-1">Address 2</FormLabel>
                <div className="flex flex-col">
                  <FormControl className="flex-1">
                    <Input 
                    test-id="org-business-address-line-two-input"
                    {...field} />
                  </FormControl>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="business_address_city"
            render={({ field }) => (
              <FormItem className="flex items-center my-2 justify-between">
                <FormLabel className="flex-1">City</FormLabel>
                <div className="flex flex-col">
                  <FormControl className="flex-1">
                    <Input 
                    test-id="org-business-address-city-input"
                    {...field} />
                  </FormControl>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="business_address_state"
            render={({ field }) => (
              <FormItem className="flex items-center">
                <FormLabel className="flex-1">State</FormLabel>
                <Select
                  onValueChange={field.onChange}
                  defaultValue={field.value}
                >
                  {/* The difference in padding in firefox and chrome is insane */}
                  {/* pl-4 is the closest hack I can get both of them to look ok */}
                  <div className="flex flex-1 flex-col pl-4">
                    <FormControl>
                      <SelectTrigger test-id="state-dropdown">
                        <SelectValue />
                      </SelectTrigger>
                    </FormControl>
                    <FormMessage />
                  </div>
                  <SelectContent>
                    {statesList.map((state) => (
                      <SelectItem
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="business_address_postal_code"
            render={({ field }) => (
              <FormItem className="flex items-center my-2 justify-between">
                <FormLabel className="flex-1">Zip Code</FormLabel>
                <div className="flex flex-col">
                  <FormControl className="flex-1">
                    <Input 
                    test-id="org-business-address-postal-code-input"
                    placeholder="00000" {...field} />
                  </FormControl>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <h1 className="text-xl font-bold">Primary Contact Information</h1>
          <FormField
            control={form.control}
            name="primary_contact_full_name"
            render={({ field }) => (
              <FormItem className="flex items-center my-2 justify-between">
                <FormLabel className="flex-1">Primary Contact Name</FormLabel>
                <div className="flex flex-col">
                  <FormControl className="flex-1">
                    <Input 
                    test-id="org-primary-contact-full-name-input"
                    {...field} />
                  </FormControl>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="primary_contact_phone_number"
            render={({ field }) => (
              <FormItem className="flex items-center my-2 justify-between">
                <FormLabel className="flex-1">Phone number</FormLabel>
                <div className="flex flex-col">
                  <FormControl className="flex-1">
                    <Input 
                    test-id="org-primary-contact-phone-number-input"
                    placeholder="123-456-7890" {...field} />
                  </FormControl>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="primary_contact_email"
            render={({ field }) => (
              <FormItem className="flex items-center my-2 justify-between">
                <FormLabel className="flex-1">Email address</FormLabel>
                <div className="flex flex-col">
                  <FormControl className="flex-1">
                    <Input 
                    test-id="org-primary-contact-email-input"
                    {...field} />
                  </FormControl>
                  <FormMessage />
                </div>
              </FormItem>
            )}
          />
          <div className="flex justify-between">
            <div>{statusMessage}</div>
            <Button loading={loading}>Save</Button>
          </div>
        </form>
      </Form>
    </div>
  );
}
