import { IOrganization } from '@/components/chat/types.ts';
import { Link } from 'react-router-dom';

interface SidebarProps {
  organization: IOrganization;
}

const Sidebar = ({ organization }: SidebarProps) => {
  return (
    <aside className="py-8 px-16 border-0 border-r-2">
      <h3 className="text-xl font-semibold">{organization?.name}</h3>
      <ul className="mt-8 text-xs">
        <li className="font-bold">Dashboard</li>
        <li>
          <a href="#" className="underline text-[blue]">
            Action Items
          </a>
        </li>
        <li>
          <Link to="/dashboard/participants" className="underline text-[blue]">
            Participants
          </Link>
        </li>
        <li className="font-bold text-xs mt-4">Control Panel</li>
        <li>
          <a href="/dashboard/organization" className="underline text-[blue]">
            Organization
          </a>
        </li>
        <li>
          <a href="#" className="underline text-[blue]">
            Partners
          </a>
        </li>
        <li>
          <a href="#" className="underline text-[blue]">
            Marketplace
          </a>
        </li>
        <li>
          <a href="#" className="underline text-[blue]">
            User Accounts
          </a>
        </li>
        <li>
          <a href="#" className="underline text-[blue]">
            Governance
          </a>
        </li>
        <li>
          <a href="#" className="underline text-[blue]">
            Reporting
          </a>
        </li>
      </ul>
    </aside>
  );
};

export default Sidebar;
