import Chat from '@/components/chat';
import Header from '@/components/header';
import { useAuth } from '@/providers/auth.tsx';
import { Navigate } from 'react-router-dom';

function App() {
  const { user } = useAuth();

  if (user.role === 'admin') {
    return <Navigate to="/dashboard" />;
  }

  console.log('app component is rendering');

  return (
    <div className="bg-app-background h-full overflow-auto disable-scrollbars flex flex-col justify-between">
      <Header />
      <div className="w-full max-w-[640px] w-screen-sm mx-auto">
        <Chat />
      </div>
    </div>
  );
}

export default App;
