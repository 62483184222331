import logo from '@/assets/images/logo/primary-orange.svg';
import { Card, CardContent } from '@/components/ui/card.tsx';
import AcceptInviteForm from '@/routes/invite/accept-invite-form.tsx';
import { IInvite } from '@/types.ts';

// A normal invite object with the ID appended
interface SubmitData extends IInvite {
  invite_id: string;
}

interface InviteAcceptProps {
  invite: IInvite | null;
  invalid: boolean;
  onConfirmData: (_data: SubmitData) => void;
}

// This is the initial view that a user would see if they are accepting an
// invitation. This contains the form that would verify their information,
// whether a sponsor or participant, and then proceed to the next step in the flow.
const InviteAccept = ({
  invite,
  invalid,
  onConfirmData,
}: InviteAcceptProps) => {
  return (
    <div className="max-w-md mx-auto flex-1">
      {invite?.role === 'admin' ? (
        <div>
          <h1 className="text-2xl font-bold">
            Administrator Account Registration
          </h1>
          <div className="my-4">
            <p>
              Enter your name and email address to create a Peopled admin
              account.
            </p>
            <p className="italic">All Fields Required</p>
          </div>
        </div>
      ) : (
        <>
          <h5 className="text-center font-montserrat-alternates font-medium mb-[-10px]">
            Welcome to
          </h5>
          <img src={logo} alt="Logo" />
        </>
      )}

      <Card>
        {invalid ? (
          <p className="text-destructive font-bold text-center">
            Invalid Token
          </p>
        ) : null}
        <CardContent>
          {invite ? (
            <AcceptInviteForm invite={invite} onSubmit={onConfirmData} />
          ) : null}
        </CardContent>
      </Card>
    </div>
  );
};

export default InviteAccept;
