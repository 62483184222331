import { IUser } from '@/components/chat/types.ts';

export default function AccountContactDetails({ user }: { user: IUser }) {
  const {
    address_line_1,
    address_line_2,
    address_city,
    address_state,
    address_postal_code,
    phone_number,
  } = user.user_detail ?? {};

  return (
    <div className="flex flex-col gap-y-2 mt-4 text-sm">
      <div>
        <p test-id="contact-full-name">
          {user.first_name} {user.last_name}
        </p>
      </div>
      <div>
        <p className="font-bold">Email</p>
        <p test-id="personal-email">{user.email} (primary)</p>
        <p test-id="personal-email-secondary">
          {user.email_secondary
            ? user.email_secondary + ' (secondary)'
            : 'no secondary email'}{' '}
        </p>
      </div>
      <div>
        <p className="font-bold">Address</p>
        <address>
          <p test-id="personal-address-line-1">
            {address_line_1 ?? 'No address provided'}
          </p>
          <p test-id="personal-address-line-2">{address_line_2 ?? ''}</p>
          <p test-id="personal-address-city">
            {address_city ?? 'No city provided'}
          </p>
          <p test-id="personal-address-state">
            {address_state ?? 'No state provided'}
          </p>
          <p test-id="personal-address-postal-code">
            {address_postal_code ?? 'No postal code provided'}
          </p>
        </address>
      </div>
      <div>
        <p>Phone</p>
        <p test-id="personal-phone">{phone_number ? phone_number : 'N/A'}</p>
      </div>
    </div>
  );
}
