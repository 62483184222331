import ProtectedRoute from '@/components/protected-route.tsx';
import { createBrowserRouter, Navigate } from 'react-router-dom';
import Login from '@/routes/login';
import App from '@/routes/app.tsx';
import Account from '@/routes/account';
import AccountPersonal from '@/routes/account/personal';
import AccountContact from '@/routes/account/contact';
import AccountChatHistory from '@/routes/account/chat-history';
import Invite from '@/routes/invite';
import Files from '@/routes/files';
import Profile from '@/routes/account/profile';
import Dashboard from '@/routes/dashboard';
import Participants from '@/routes/dashboard/participants';
import Invites from '@/routes/dashboard/participants/invites.tsx';
import Organization from '@/routes/dashboard/organization';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Navigate to="/app" />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/invite',
    element: <Invite />,
  },
  {
    path: '/app',
    element: <ProtectedRoute element={<App />} />,
  },
  {
    path: '/dashboard',
    element: <ProtectedRoute element={<Dashboard />} />,
    children: [
      {
        path: 'organization',
        element: <Organization />,
      },
      {
        path: 'participants',
        element: <Participants />,
      },
      {
        path: 'participants/invites',
        element: <Invites />,
      },
    ],
  },
  {
    path: '/files/:organizationId/documents/:documentId',
    element: <ProtectedRoute element={<Files />} />,
  },
  {
    path: '/app/account',
    element: <ProtectedRoute element={<Account />} />,
    children: [
      {
        path: 'personal',
        element: <AccountPersonal />,
      },
      {
        path: 'contact',
        element: <AccountContact />,
      },
      {
        path: 'chat-history',
        element: <AccountChatHistory />,
      },
      {
        path: 'profile',
        element: <Profile />,
      },
    ],
  },
]);

export default router;
