import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form.tsx';
import { Input } from '@/components/ui/input.tsx';
import { Button } from '@/components/ui/button.tsx';
import { LoadingSpinner } from '@/components/ui/loading-spinner.tsx';
import { z } from 'zod';
import { useState } from 'react';
import { useAuth } from '@/providers/auth.tsx';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const formSchema = z.object({
  email: z.string().email({ message: 'Invalid email address' }),
  password: z.string().min(2),
});

const LoginPasswordForm = () => {
  const [submitLoading, setSubmitLoading] = useState(false);
  const [error, setError] = useState(false);
  const { login } = useAuth();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async (credentials: z.infer<typeof formSchema>) => {
    setSubmitLoading(true);
    const { email, password } = credentials;
    try {
      await login(email, password);
      setSubmitLoading(false);
    } catch (error) {
      setSubmitLoading(false);
      setError(true);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Email</FormLabel>
              <FormControl>
                <Input type="email" placeholder="Email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Password</FormLabel>
              <FormControl>
                <Input type="password" placeholder="Password" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex space-between items-center">
          <div className="flex-1 pl-2 text-destructive">
            {error ? 'Invalid email or password' : ''}
          </div>
          <Button type="submit">
            {!submitLoading ? (
              'Submit'
            ) : (
              <div className="px-3">
                <LoadingSpinner />
              </div>
            )}
          </Button>
        </div>
      </form>
    </Form>
  );
};

export default LoginPasswordForm;
