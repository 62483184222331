import { Button } from '@/components/ui/button.tsx';
import { useEffect, useState } from 'react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
} from '@/components/ui/dialog.tsx';
import { sdk } from '@/api.ts';
import { DataTable } from '@/components/data-table.tsx';
import { columns } from '@/routes/dashboard/participants/columns.tsx';
import { Card } from '@/components/ui/card.tsx';
import InviteForm from '@/routes/dashboard/participants/invite-form.tsx';

const Invites = () => {
  const [addInviteModalOpen, setAddInviteModalOpen] = useState(false);
  const [invites, setInvites] = useState([]);

  const fetchInvites = () => {
    sdk
      .getInvites()
      .then((response) => {
        setInvites(response.data.invites);
      })
      .catch((error) => {
        console.error(error);
        // @TODO: Handle error when UX provides an error strategy
      });
  };

  useEffect(() => {
    fetchInvites();
  }, []);

  return (
    <div>
      <Dialog open={addInviteModalOpen} onOpenChange={setAddInviteModalOpen}>
        <DialogContent>
          <DialogTitle>Invite new user</DialogTitle>
          <DialogDescription className="hidden">
            Invite new user
          </DialogDescription>
          <InviteForm onSuccess={fetchInvites} />
        </DialogContent>
      </Dialog>
      <div className="flex justify-between">
        <h3 className="font-bold text-xl">Invites</h3>
        <Button onClick={() => setAddInviteModalOpen(!addInviteModalOpen)}>
          Invite user
        </Button>
      </div>
      <Card className="mt-4">
        <DataTable columns={columns} data={invites} />
      </Card>
    </div>
  );
};

export default Invites;
