import { useAuth } from '@/providers/auth.tsx';
import { Card, CardContent } from '@/components/ui/card.tsx';
import logo from '@/assets/images/logo/primary-orange.svg';
import { Navigate, useSearchParams } from 'react-router-dom';
import OTPLogin from '@/routes/login/otp-login';
import { useState } from 'react';
import LoginPasswordForm from '@/routes/login/password-form.tsx';
import { Button } from '@/components/ui/button.tsx';
import { BiInfoCircle } from 'react-icons/bi';

export default function Login() {
  const [searchParams] = useSearchParams();
  // Has a user been sent here after accepting an invite?
  const isFromAcceptedInvite = searchParams.get('invite_accepted');
  const { user, loading } = useAuth();
  const [showOTPForm, setShowOTPForm] = useState(true);
  // If user /auth/me hasn't finished loading, don't yet check for user
  if (loading) {
    return <></>;
  }

  if (user) {
    return <Navigate to="/app" />;
  }

  return (
    <div className="bg-app-background h-full flex justify-center items-center">
      <div className="max-w-md mx-auto flex-1">
        <div className="px-20">
          <h5 className="text-center font-montserrat-alternates font-medium mb-[-10px]">
            Welcome to
          </h5>
          <img src={logo} alt="Logo" />
        </div>
        {isFromAcceptedInvite ? (
          <Card className="flex items-center mb-2">
            <BiInfoCircle size={24} className="mr-2" />
            Please log in with the email you just registered.
          </Card>
        ) : null}
        <Card>
          <CardContent>
            {showOTPForm ? <OTPLogin /> : <LoginPasswordForm />}
            {!isFromAcceptedInvite ? (
              <div className="flex justify-center">
                <Button
                  test-id="otp-toggle"
                  variant="link"
                  className="underline"
                  onClick={() => setShowOTPForm(!showOTPForm)}
                >
                  {showOTPForm
                    ? 'Log in with email + password'
                    : 'Use one time password'}
                </Button>
              </div>
            ) : null}
          </CardContent>
        </Card>
      </div>
    </div>
  );
}
