import face from '@/assets/images/face.svg';
import { LoadingSpinner } from '@/components/ui/loading-spinner.tsx';
import ChatRecommendation from '@/components/chat/chat-recommendation.tsx';

function getParsedMessage(message: string) {
  let containsBraces = false;
  let recommendationName = '';
  const parsedMessage = message.replace(/\{\{([^}]+)}}/g, (_, matchedGroup) => {
    containsBraces = true;
    const [name] = matchedGroup.split(':');
    recommendationName = name.trim();
    return name.trim();
  });

  return { parsedMessage, recommendationName, containsBraces };
}

export default function ChatResponse({
  type,
  message,
  onClickRecommendation,
  loading = false,
}: {
  message: string;
  loading?: boolean;
  onClickRecommendation?: () => void;
  type: 'user' | 'bot';
}) {
  const baseStyles = 'chat-response p-4 rounded-lg text-dark font-medium max-w-[480px]';
  if (type === 'user') {
    return (
      <div className={`${baseStyles} bg-white`}>
        <div>
          {message}
        </div>
      </div>
    );
  }

  return (
    <div className={`${baseStyles} flex items-start`}>
      <img className="mr-4" src={face} alt="chat user" />
      {loading ? (
        <LoadingSpinner className="mt-1" color="hsl(var(--input-foreground))" />
      ) : (
        <div className="flex flex-col gap-2">
          <div
            className="markdown"
            dangerouslySetInnerHTML={{
              __html: getParsedMessage(message).parsedMessage,
            }}
          />
          {getParsedMessage(message).containsBraces && (
            <ChatRecommendation
              name={getParsedMessage(message).recommendationName}
              description="This is a recommendation description."
              onClick={onClickRecommendation!}
            />
          )}
        </div>
      )}
    </div>
  );
}
