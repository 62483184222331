import { BiChevronLeft, BiUser } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import { Card } from '@/components/ui/card.tsx';

// Will be a prop, or a result of fetched data
const mockUsers = [{}, {}, {}, {}];
const mockInvites = [{}, {}, {}, {}];
const mockMetrics = {
  usersThisWeek: 123,
  noticesThisMonth: 2,
  clickThroughRate: '35%',
};
const Participants = () => {
  const users = mockUsers;
  const invites = mockInvites;
  const metrics = mockMetrics;

  return (
    <div className="text-sm">
      <h1 className="text-2xl flex relative items-center font-semibold">
        <BiUser size={32} className="mr-2" />
        Participants
      </h1>
      <Link to="/dashboard" className="flex items-center mt-2 underline">
        <BiChevronLeft className="mr-2" size="24" />
        Back to Dashboard
      </Link>

      <Card className="flex flex-wrap gap-x-8 mt-4">
        <div className="mt-4">
          <h2 className="font-semibold text-lg">Participants</h2>
          <p>
            <span className="font-bold">{users.length}</span> total participants
          </p>
          <p>
            <span className="font-bold">{metrics.usersThisWeek}</span>{' '}
            participants used Peopled this week
          </p>

          <Link
            to="/dashboard/participants/all"
            className="underline mt-4 block"
          >
            View Participants &gt;
          </Link>
          <Link to="#" className="underline mt-4 block">
            Remove Participant &gt;
          </Link>
        </div>

        <div className="mt-4">
          <h2 className="font-semibold text-lg">Invitations</h2>
          <p>
            <span className="font-bold">{invites.length}</span> total invites
          </p>
          <p>
            <span className="font-bold">{metrics.usersThisWeek}</span>
            &nbsp;invitations accepted this week
          </p>

          <Link
            to="/dashboard/participants/invites"
            className="underline mt-4 block"
          >
            View Invitations &gt;
          </Link>
          <Link
            to="/dashboard/participants/invites"
            className="underline mt-4 block"
          >
            Single Invite &gt;
          </Link>
          <Link
            to="/dashboard/participants/invites"
            className="underline mt-4 block"
          >
            Batch Invite &gt;
          </Link>
          <Link
            to="/dashboard/participants/invites"
            className="underline mt-4 block"
          >
            Re-send Invitations &gt;
          </Link>
          <Link
            to="/dashboard/participants/invites"
            className="underline mt-4 block"
          >
            Cancel Invitation &gt;
          </Link>
        </div>
        <div className="mt-4 basis-[100%]">
          <h2 className="font-semibold text-lg">Communications</h2>
          <p>
            <span className="font-bold">{metrics.noticesThisMonth}</span> total
            invites
          </p>
          <p>
            <span className="font-bold">{metrics.usersThisWeek}</span>
            invitations accepted this week
          </p>

          <Link to="#" className="underline mt-4 block">
            View Communications &gt;
          </Link>
          <Link to="#" className="underline mt-4 block">
            Create New &gt;
          </Link>
        </div>
      </Card>
    </div>
  );
};

export default Participants;
