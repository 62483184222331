import { Button } from '@/components/ui/button.tsx';
import { BiChevronRight } from 'react-icons/bi';
import { MouseEvent } from 'react';
import { LoadingSpinner } from '@/components/ui/loading-spinner.tsx';

interface IChatCtaProps {
  disabled?: boolean;
  loading?: boolean;
  onClick: (event: MouseEvent<HTMLButtonElement>) => void;
}

export default function ChatCta({
  disabled = false,
  loading = false,
  onClick,
}: IChatCtaProps) {
  const loadingClasses = loading ? 'bg-input' : '';
  return (
    <Button
      disabled={disabled}
      onClick={onClick}
      test-id="chat-cta"
      className={`rounded-full flex justify-between items-center px-3 bg-primary text-white hover:opacity-80 disabled:bg-input disabled:opacity-1 disabled:text-primary ${loadingClasses}`}
    >
      {loading ? (
        <LoadingSpinner size={16} className="text-primary" />
      ) : (
        <BiChevronRight size={16} />
      )}
    </Button>
  );
}
