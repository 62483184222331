import { ColumnDef } from '@tanstack/react-table';
import { format } from 'date-fns';

export type Invite = {
  email: string;
  first_name: string;
  last_name: string;
  created_at: string;
};

export const columns: ColumnDef<Invite>[] = [
  {
    accessorKey: 'email',
    header: 'Email',
  },
  {
    accessorKey: 'first_name',
    header: 'First Name',
  },
  {
    accessorKey: 'last_name',
    header: 'Last Name',
  },
  {
    accessorKey: 'created_at',
    header: 'Created At',
    cell: ({ row }) => {
      const date: string = row.getValue('created_at');
      return format(new Date(date), 'MM/dd/yy');
    },
  },
];
