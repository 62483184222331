import { sdk } from '@/api.ts';

const originalTheme = {
  colors: {
    primary: '#31214C',
    'app-background': '#fdf8e4',
    linear1: '#ce67a0',
    linear2: '#dd7a62',
    linear3: '#c1c770',
  },
  images: {
    logo: null,
  },
};

/**
 * setTheme() sets the theme in localStorage and updates the CSS variables.
 * @param theme - The theme object to set in localStorage.
 * Initially sets the original theme in localStorage for later retrieval on reset, then sets the new theme.
 */
export const setTheme = (theme: any) => {
  localStorage.setItem('theme', JSON.stringify(theme));
  const root = document.documentElement;
  for (const key in theme.colors) {
    root.style.setProperty(`--${key}`, hexToHSL(theme.colors[key]));
  }
};

/**
 * fetchAndSetTheme() fetches the theme from the API and sets it in localStorage.
 */
export const fetchAndSetTheme = async () => {
  return sdk.getTheme().then((response) => {
    setTheme(response.data);
  });
};

/**
 * resetTheme() resets the theme in localStorage to the original theme.
 */
export const resetTheme = () => {
  localStorage.removeItem('theme');
  setTheme(originalTheme);
};

/**
 * getTheme() retrieves the theme from localStorage and returns it as a javascript object.
 */
export const getTheme = () => {
  const theme = localStorage.getItem('theme');
  const parsedTheme = theme ? JSON.parse(theme) : { images: null };
  let logoUrl;
  if (parsedTheme && parsedTheme.images?.logo) {
    logoUrl = parsedTheme.images.logo;
  }

  return {
    ...parsedTheme,
    ...(logoUrl && { logoUrl }),
  };
};

function hexToHSL(hex: string) {
  // Convert hex to RGB first
  let r: any = 0,
    g: any = 0,
    b: any = 0;
  if (hex.length == 4) {
    r = '0x' + hex[1] + hex[1];
    g = '0x' + hex[2] + hex[2];
    b = '0x' + hex[3] + hex[3];
  } else if (hex.length == 7) {
    r = '0x' + hex[1] + hex[2];
    g = '0x' + hex[3] + hex[4];
    b = '0x' + hex[5] + hex[6];
  }
  // Then to HSL
  r /= 255;
  g /= 255;
  b /= 255;
  const cmin = Math.min(r, g, b),
    cmax = Math.max(r, g, b),
    delta = cmax - cmin;

  let h = 0,
    s = 0,
    l = 0;

  if (delta == 0) h = 0;
  else if (cmax == r) h = ((g - b) / delta) % 6;
  else if (cmax == g) h = (b - r) / delta + 2;
  else h = (r - g) / delta + 4;

  h = Math.round(h * 60);

  if (h < 0) h += 360;

  l = (cmax + cmin) / 2;
  s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
  s = +(s * 100).toFixed(1);
  l = +(l * 100).toFixed(1);

  return h + ',' + s + '%,' + l + '%';
}
